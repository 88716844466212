import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/clientcss/ClientSettings.css'; // Import the CSS file

const UserSettings = () => {
  const [user, setUser] = useState(null);
  const [detail, setDetail] = useState({ first_name: '', last_name: '', email: '', address: '', phone_number: '' });
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    if (message) {
      setIsVisible(true); // Make the popup visible
      const timer = setTimeout(() => setIsVisible(false), 5000);
      const fadeOutTimer = setTimeout(() => setMessage(''), 6000);
      return () => {
        clearTimeout(timer);
        clearTimeout(fadeOutTimer);
      };
    }
  }, [message]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userResponse = await axios.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUser(userResponse.data);

        const detailsResponse = await axios.get(`/client-details/get-details/${userResponse.data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (detailsResponse.data.length > 0) {
          setDetail(detailsResponse.data[0]);
        }
      } catch (error) {
        console.error('Failed to fetch user data or details', error);
      }
    };

    fetchUserData();
  }, []);

  const handleSaveDetail = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(detail.id ? '/client-details/update-detail' : '/client-details/add-detail', 
      { 
        ...detail, 
        clientId: user.id 
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setDetail(response.data);
      setMessage('Details saved successfully');
    } catch (error) {
      console.error('Error saving details', error.response ? error.response.data : error.message);
      setMessage('Error saving details');
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (

  <>
    {message && (
      <div className={`message-popup ${!isVisible ? 'message-popup-hidden' : ''}`}>
        {message}
      </div>
    )}

    <div className="user-settings-container">
      <h2>Account Setup</h2>
      <p>User ID: {user.id}</p>

      <div className="user-settings-form">
        <h3>Account Information</h3>
        <input
          type="text"
          className="first-name"
          value={detail.first_name}
          onChange={(e) => setDetail({ ...detail, first_name: e.target.value })}
          placeholder="First Name"
        />
        <input
          type="text"
          className="last-name"
          value={detail.last_name}
          onChange={(e) => setDetail({ ...detail, last_name: e.target.value })}
          placeholder="Last Name"
        />
        <input
          type="email"
          value={detail.email}
          onChange={(e) => setDetail({ ...detail, email: e.target.value })}
          placeholder="Email"
        />
        <input
          type="text"
          value={detail.address}
          onChange={(e) => setDetail({ ...detail, address: e.target.value })}
          placeholder="Address"
        />
        <input
          type="text"
          value={detail.phone_number}
          onChange={(e) => setDetail({ ...detail, phone_number: e.target.value })}
          placeholder="Phone Number"
        />
        <button onClick={handleSaveDetail}>Save Details</button>
      </div>
    </div>
  </>
  );
};

export default UserSettings;
