import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import './css/Login.css'; // Importing the CSS file
import companynameimage from './img/companyname.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const response = await axios.post('/auth/login', { email, password });
      console.log('Login successful:', response.data);
      setMessage('Login successful');
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.client));

      if (response.data.client.is_admin) {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setMessage('Login failed');
    }
  };

  return (
    <div className="login-page">
      <img src={companynameimage} alt="Company Name" className="company-name-logo" />
      <div className="login-form-container">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="login-input"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="login-input"
        />
        <button onClick={handleLogin} className="login-button">Login</button>
      </div>
      <p className="login-message">{message}</p>
    </div>
  );
};

export default Login;
