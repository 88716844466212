import React from 'react';
import { Link, useHistory } from 'react-router-dom'; // Use Link and useHistory for navigation
import './css/Store.css'; // Import the CSS for styling
import logo from './img/logo.png'; // Import the logo
import frontstation from './img/frontstation.png'; // Import product images
import lock from './img/lock.png';
import dolly from './img/dolly.png';

const StorePage = () => {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  const handleBuyNow = (formId) => {
    document.getElementById(formId).submit();
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="header-right">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/store">Products</Link></li>
              <li><Link to="/FAQ">FAQ</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <button onClick={redirectToLogin} className="login-button-horizontal">Login</button>
        </div>
      </header>

      {/* Content Wrapper */}
        <main className="store-main">
          <div className="store-product-container">
            <div className="store-product-card">
              <div className="store-product-img">
                <img src={frontstation} alt="KT Dolly Station" />
              </div>
              <div className="store-product-info">
                <div className="store-product-text">
                  <h1>KT Dolly Station</h1>
                  <p>
                    The KT Dolly Station comes with a fully stocked shelving unit of six dollies and six KT locks.
                    Everything you need to get started renting to your customers.
                    Requires a wall outlet and WIFI connection.
                  </p>
                </div>
                <div className="store-product-price-btn">
                  <p>$299.99</p>
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" id="paypalForm1">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="3XZJJ6KRWQDD6" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <button type="button" onClick={() => handleBuyNow('paypalForm1')}>Buy Now</button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="store-product-container">
            <div className="store-product-card">
                <div className="store-product-img">
                    <img src={lock} alt="KT Lock with Wall Mount" />
                </div>
                <div className="store-product-info">
                    <div className="store-product-text">
                        <h1>KT Lock with Wall Mount</h1>
                        <p>
                            Our innovative lock can be installed on any equipment, instantly turning it into a profitable rental asset.
                            With integrated GPS, Wi-Fi, and cellular capabilities, you’ll always have real-time tracking and location updates.
                        </p>
                    </div>
                    <div className="store-product-price-btn">
                        <p>$39.99 + $5/month</p>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" id="paypalForm2">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="2REB2D34QVN5E" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <button type="button" onClick={() => handleBuyNow('paypalForm2')}>Buy Now</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>


          <div className="store-product-container">
            <div className="store-product-card">
              <div className="store-product-img">
                <img src={dolly} alt="KT Dolly" />
              </div>
              <div className="store-product-info">
                <div className="store-product-text">
                  <h1>KT Dolly</h1>
                  <p>
                    Supporting up to 1000 pounds, the KT Dolly combines strength with lightweight versatility.
                    Its compact size ensures it’s perfect for any task, big or small.
                  </p>
                </div>
                <div className="store-product-price-btn">
                  <p>$29.99</p>
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" id="paypalForm3">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="SGEBXHMQQMN7U" />
                    <input type="hidden" name="currency_code" value="USD" />
                    <button type="button" onClick={() => handleBuyNow('paypalForm3')}>Buy Now</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer className="landing-footer">
        <p>© 2024 Your Company. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default StorePage;
