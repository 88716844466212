import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminProductManager = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch all clients when the component mounts
    const fetchClients = async () => {
      try {
        const response = await axios.get('/admin/clients');
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientChange = async (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);

    if (clientId) {
      setIsLoading(true);
      try {
        // Fetch products for the selected client
        const response = await axios.get(`/client-products/get-products/${clientId}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setProducts([]);
    }
  };

  return (
    <div>
      <h1>Admin Product Manager</h1>
      <div>
        <label htmlFor="client-select">Select Client:</label>
        <select id="client-select" onChange={handleClientChange}>
          <option value="">--Select a Client--</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.email}
            </option>
          ))}
        </select>
      </div>
      <div>
        <h2>Products for {selectedClient ? clients.find(client => client.id === selectedClient)?.email : 'Select a Client'}</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {products.map((product) => (
              <li key={product.id}>
                {product.product_name} - Deposit: {product.deposit_amount} - Hourly Amount: {product.hourly_amount} - Device Number: {product.device_number}
                {product.product_image && (
                  <div>
                    <img src={product.product_image} alt={product.product_name} width="100" />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AdminProductManager;
