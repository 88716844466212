import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DeviceManager = () => {
  const [devices, setDevices] = useState([]);
  const [message, setMessage] = useState('');
  const [searchDeviceNumber, setSearchDeviceNumber] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/device/device_hub', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setDevices(response.data);
      } catch (error) {
        console.error('Error fetching devices', error);
        setMessage('Error fetching devices');
      }
    };

    fetchDevices();
  }, []);

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/device/device_status/${searchDeviceNumber}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSearchResult(response.data);
      setMessage('');
    } catch (error) {
      console.error('Error fetching device status', error);
      setMessage('Device not found');
      setSearchResult(null);
    }
  };

  // Function to convert the timestamp to human-readable format
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // You can customize this for specific formats
  };

  return (
    <div>
      <h3>Device Manager</h3>
      <p>{message}</p>
      <div>
        <h4>Search Device</h4>
        <input
          type="text"
          value={searchDeviceNumber}
          onChange={(e) => setSearchDeviceNumber(e.target.value)}
          placeholder="Enter device number"
        />
        <button onClick={handleSearch}>Search</button>
      </div>
      {searchResult && (
        <div>
          <h4>Search Result</h4>
          <p>
            <strong>ID:</strong> {searchResult.device_number} <br />
            <strong>Name:</strong> {searchResult.product_name} <br />
            <strong>Status:</strong> {searchResult.device_status} <br />
            <strong>Last Online:</strong> {formatDate(searchResult.device_last_check_in)} <br />
            <strong>WiFi Connection:</strong> {searchResult.wifi_connection} <br />
            {/* Add more fields as necessary */}
          </p>
        </div>
      )}
      <div>
        <h4>Devices</h4>
        <ul>
          {devices.map(device => (
            <li key={device.device_number}>
              <strong>ID:</strong> {device.device_number} <br />
              <strong>Name:</strong> {device.product_name} <br />
              <strong>Status:</strong> {device.device_status} <br />
              <strong>Last Online:</strong> {formatDate(device.device_last_check_in)} <br />
              <strong>WiFi Connection:</strong> {device.wifi_connection} <br />
              {/* Add more fields as necessary */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DeviceManager;
