import React from 'react';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import ClientManager from './AdminClientManager';
import DeviceManager from './AdminDeviceManager';
import AdminProductManager from './AdminProductManager';
import AdminRentalStatus from './AdminRentalStatus'; // Import the new component

const AdminDashboard = () => {
  const { path, url } = useRouteMatch();

  return (
    <div>
      <h2>Admin Dashboard</h2>
      <nav>
        <ul>
          <li>
            <Link to={`${url}/clients`}>Client Manager</Link>
          </li>
          <li>
            <Link to={`${url}/devices`}>Device Manager</Link>
          </li>
          <li>
            <Link to={`${url}/products`}>Product Manager</Link>
          </li>
          <li>
            <Link to={`${url}/rental-status`}>Rental Status</Link>
          </li>
        </ul>
      </nav>
      <Switch>
        <Route exact path={path}>
          <h3>Please select a tab.</h3>
        </Route>
        <Route path={`${path}/clients`}>
          <ClientManager />
        </Route>
        <Route path={`${path}/devices`}>
          <DeviceManager />
        </Route>
        <Route path={`${path}/products`}>
          <AdminProductManager />
        </Route>
        <Route path={`${path}/rental-status`}>
          <AdminRentalStatus />
        </Route>
      </Switch>
    </div>
  );
};

export default AdminDashboard;
