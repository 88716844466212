import React from 'react';
import { Route, Switch, Link, useRouteMatch, Redirect } from 'react-router-dom';
import DeviceManagement from './DeviceManagement';
import UserSettings from './ClientSettings';
import RentalInformation from './RentalInformation';
import QRCodePage from './QRCode'; // Import the QRCodePage component
import DeviceSetup from './DeviceSetup'; // Import DeviceSetup component
import '../css/clientcss/UserDashboard.css'; // Import the CSS file

const UserDashboard = () => {
  let { path, url } = useRouteMatch();

  return (
    <div className="dashboard-container">
      <nav className="sidebar">
        <ul>
          <li>
            <Link to={`${url}/device-management`}>Device Management</Link>
          </li>
          <li>
            <Link to={`${url}/qr-code`}>QR Code</Link>
          </li>
          <li>
            <Link to={`${url}/rental-information`}>Rental Information</Link>
          </li>
          <li>
            <Link to={`${url}/device-setup`}>Device Setup Instructions</Link>
          </li>
          <li>
            <Link to={`${url}/settings`}>Settings</Link>
          </li>
        </ul>
      </nav>
      <div className="dashboard-content-wrapper">
        <div className="dashboard-content">
          <h2 className="dashboard-header">User Dashboard</h2>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/device-management`} />
            </Route>
            <Route path={`${path}/device-management`} component={DeviceManagement} />
            <Route path={`${path}/qr-code`} component={QRCodePage} />
            <Route path={`${path}/rental-information`} component={RentalInformation} />
            <Route path={`${path}/device-setup`} component={DeviceSetup} />
            <Route path={`${path}/settings`} component={UserSettings} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
