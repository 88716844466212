import React, { useState, useEffect } from 'react';
import '../css/clientcss/QRCode.css'; // Import your CSS file

const QRCodePage = () => {
  const [qrCode, setQrCode] = useState(''); // Store the QR code as base64
  const [user, setUser] = useState(null); // Store user data

  // Fetch user data when the component loads
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token'); // Fetch the token from localStorage
        const userResponse = await fetch('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        });
        const userData = await userResponse.json();
        setUser(userData); // Set the user data (including user ID)

        // After fetching the user, fetch the QR code for that user
        if (userData && userData.id) {
          generateQRCode(token); // Pass the token to the QR code generator function
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  // Function to fetch the QR code using the user's token
  const generateQRCode = async (token) => {
    try {
      const response = await fetch(`/qrcode/generate`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers for QR code generation
        },
      });
      const data = await response.json();
      setQrCode(data.qrCode); // Set the base64 QR code data
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  // Function to download the QR code image
  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCode; // Set the base64 data as the image source
    link.download = `${user ? user.name : 'QR_Code'}.png`; // Name the downloaded file
    link.click(); // Trigger the download
  };

  return (
    <div className="qr-code-page">
      <h1>Customer QR Code</h1>

      {qrCode ? (
        <div className="qr-code-container">
          {/* QR Code with a white background */}
          <div className="qr-code-wrapper">
            <img src={qrCode} alt="Generated QR Code" />
          </div>
          <button className="download-button" onClick={downloadQRCode}>
            Download QR Code
          </button>
        </div>
      ) : (
        <p className="loading-text">Loading QR code...</p>
      )}
    </div>
  );
};

export default QRCodePage;
