import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminRentalStatus = () => {
  const [rentalStatus, setRentalStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRentalStatus = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('/rental/rental_status');
        
        if (Array.isArray(response.data)) {
          // Sort the data by 'last_updated' in descending order
          const sortedData = response.data.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
          setRentalStatus(sortedData); // Set the sorted data to state
        } else {
          setError('Unexpected response format');
        }
      } catch (error) {
        console.error('Error fetching rental status:', error);
        setError('Error fetching rental status');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRentalStatus();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const formatAmount = (amountInCents) => {
    return `$${(amountInCents / 100).toFixed(2)}`;
  };

  const formatHourlyAmount = (hourlyAmount) => {
    return `$${parseFloat(hourlyAmount).toFixed(2)}`;
  };

  const formatDateTime = (dateTime) => {
    return dateTime ? new Date(dateTime).toLocaleString() : 'N/A';
  };

  return (
    <div>
      <h1>Rental Status</h1>
      <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Customer ID</th>
          <th>Product ID</th>
          <th>Hold Amount</th>
          <th>Currency</th>
          <th>Status</th>
          <th>Description</th>
          <th>Amount Capturable</th>
          <th>Amount Received</th>
          <th>Payment Intent Started</th>
          <th>Payment Confirm Time</th>
          <th>Payment Capture Time</th>
          <th>Hourly Rental Amount</th>
          <th>Metadata</th>
          <th>Last Updated</th>
          <th>Device Returned Time</th>
          <th>Total Rental Time</th>
          <th>Total Transaction Amount</th>
          <th>Last Processed</th>
          <th>Retry Attempts</th>
          <th>Rental Start Time</th>
        </tr>
      </thead>
      <tbody>
        {rentalStatus.map((rental) => (
          <tr key={rental.id}>
            <td>{rental.id}</td>
            <td>{rental.customer_id}</td>
            <td>{rental.product_id}</td>
            <td>{formatAmount(rental.amount)}</td>
            <td>{rental.currency}</td>
            <td>{rental.status}</td>
            <td>{rental.description}</td>
            <td>{formatAmount(rental.amount_capturable)}</td>
            <td>{formatAmount(rental.amount_received)}</td>
            <td>{new Date(rental.payment_intent_started).toLocaleString()}</td>
            <td>{rental.payment_confirm_time ? new Date(rental.payment_confirm_time).toLocaleString() : 'N/A'}</td>
            <td>{rental.payment_capture_time ? new Date(rental.payment_capture_time).toLocaleString() : 'N/A'}</td>
            <td>{rental.metadata ? formatHourlyAmount(rental.metadata.hourly_rental_amount) : 'N/A'}</td>
            <td>{JSON.stringify(rental.metadata)}</td>
            <td>{new Date(rental.last_updated).toLocaleString()}</td>
            <td>{formatDateTime(rental.device_returned_time)}</td>
            <td>{rental.total_rental_time ? `${rental.total_rental_time.hours || 0}h ${rental.total_rental_time.minutes || 0}m ${rental.total_rental_time.seconds || 0}s` : 'N/A'}</td>
            <td>{rental.total_transaction_amount && rental.total_transaction_amount > 0 ? formatAmount(rental.total_transaction_amount) : 'N/A'}</td>
            <td>{new Date(rental.last_processed).toLocaleString()}</td>
            <td>{rental.retry_attempts}</td>
            <td>{formatDateTime(rental.rental_start_time)}</td>
          </tr>
        ))}
      </tbody>
      </table>
    </div>
  );
};

export default AdminRentalStatus;
