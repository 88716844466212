import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/clientcss/PaymentSuccess.css'; // Import the CSS file

const loadingMessages = [
  "Processing your payment...",
  "Verifying your transaction...",
  "Holding the deposit...",
  "Funds held successfully...",
  "Unlocking the device..."
];

const totalLoadingTime = 10000; // Total loading time in milliseconds (10 seconds)

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(true); // State to track loading status
  const [buttonDisabled, setButtonDisabled] = useState(true); // State for the Continue button disabled status
  const [messageIndex, setMessageIndex] = useState(0); // State to track message index
  const history = useHistory();

  // Helper function to generate random durations that sum to totalLoadingTime
  const generateRandomDurations = (numMessages, totalTime, minTime) => {
    const times = [];
    let remainingTime = totalTime;
    
    for (let i = 0; i < numMessages - 1; i++) {
      const maxTime = remainingTime - (numMessages - i - 1) * minTime;
      const randomTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
      times.push(randomTime);
      remainingTime -= randomTime;
    }

    times.push(remainingTime); // Assign the remaining time to the last message
    return times;
  };

  useEffect(() => {
    // Generate random durations that sum to 10 seconds, with each duration at least 1 second
    const randomDurations = generateRandomDurations(loadingMessages.length, totalLoadingTime, 1000);

    let messageTimeouts = [];
    let cumulativeTime = 0;

    // Schedule message changes based on the generated random durations
    randomDurations.forEach((duration, index) => {
      cumulativeTime += duration;
      const timeout = setTimeout(() => {
        setMessageIndex(index);
      }, cumulativeTime);
      messageTimeouts.push(timeout);
    });

    // Stop loading after the total loading time (10 seconds)
    const loadingTimer = setTimeout(() => {
      setLoading(false); // Stop the spinner and display the success screen
      messageTimeouts.forEach(clearTimeout); // Clear all timeouts

      // Enable the Continue button after 6 seconds
      const buttonTimer = setTimeout(() => {
        setButtonDisabled(false);
      }, 6000);
      messageTimeouts.push(buttonTimer);
    }, totalLoadingTime);

    return () => {
      clearTimeout(loadingTimer); // Cleanup the loading timer
      messageTimeouts.forEach(clearTimeout); // Cleanup all message timeouts
    };
  }, []);

  const handleContinue = () => {
    history.go(-2); // Go back two pages in history
  };

  return (
    <div className="payment-center-container">
      <div className="contentBox">
        {loading ? (
          <div className="spinner-container">
            <div className="container">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className="loading-message">{loadingMessages[messageIndex]}</p>
          </div>
        ) : (
          <>
            <div className="form-header">
              <h3 className="center-header">Time to start your rental!</h3>
            </div>
            <p>
              The device will unlock and the light will turn green. Your rental will end when the device is returned and locked. Enjoy your rental!
            </p>
            <button
              className="blue-button"
              onClick={handleContinue}
              disabled={buttonDisabled} // Grayed-out when disabled
            >
              Back to Home
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;