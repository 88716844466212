import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/clientcss/DeviceManagement.css'; // Import the CSS file

const DeviceManagement = () => {
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [deviceStatuses, setDeviceStatuses] = useState({}); // State for device statuses
  const [newProduct, setNewProduct] = useState('');
  const [newDepositAmount, setNewDepositAmount] = useState('');
  const [newHourlyAmount, setNewHourlyAmount] = useState('');
  const [newDeviceNumber, setNewDeviceNumber] = useState('');
  const [newProductImage, setNewProductImage] = useState(null);
  const [editProductId, setEditProductId] = useState(null);
  const [editProductName, setEditProductName] = useState('');
  const [editDepositAmount, setEditDepositAmount] = useState('');
  const [editHourlyAmount, setEditHourlyAmount] = useState('');
  const [editDeviceNumber, setEditDeviceNumber] = useState('');
  const [editProductImage, setEditProductImage] = useState(null);
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);



  useEffect(() => {
    if (message) {
      setIsVisible(true); // Make the popup visible
      const timer = setTimeout(() => setIsVisible(false), 5000);
      const fadeOutTimer = setTimeout(() => setMessage(''), 6000);
      return () => {
        clearTimeout(timer);
        clearTimeout(fadeOutTimer);
      };
    }
  }, [message]);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userResponse = await axios.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(userResponse.data);
  
        const productsResponse = await axios.get(
          `/client-products/get-products/${userResponse.data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProducts(productsResponse.data);
  
        // Fetch device statuses for all products once
        productsResponse.data.forEach((product) => {
          fetchDeviceStatus(product.device_number);
        });
      } catch (error) {
        console.error('Failed to fetch user data or products', error);
      }
    };
  
    const fetchDeviceStatus = async (deviceNumber) => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/device/device_status/${deviceNumber}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const { device_status, device_last_check_in } = response.data;
  
        // Check if the device is disconnected (more than 31 seconds since last check-in)
        const currentTime = new Date();
        const lastCheckInTime = new Date(device_last_check_in);
        const timeDifference = (currentTime - lastCheckInTime) / 1000; // time difference in seconds
  
        let connectionStatus = device_status;
  
        // If more than 31 seconds have passed since the last check-in, mark as disconnected
        if (timeDifference > 31) {
          connectionStatus = 'disconnected';
        } else if (device_status === 'locked') {
          connectionStatus = 'locked';
        } else if (device_status === 'unlocked') {
          connectionStatus = 'unlocked';
        }
  
        // Update the status for the specific device
        setDeviceStatuses((prevStatuses) => ({
          ...prevStatuses,
          [deviceNumber]: connectionStatus,
        }));
      } catch (error) {
        console.error('Error fetching device status', error);
        setDeviceStatuses((prevStatuses) => ({
          ...prevStatuses,
          [deviceNumber]: 'Unavailable',
        }));
      }
    };
  
    fetchUserData(); // This will run once when the component mounts
  }, []); // Empty dependency array ensures it only runs on component mount or page refresh
  
  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append('clientId', user.id);
    formData.append('productName', newProduct);
    formData.append('depositAmount', parseFloat(newDepositAmount));
    formData.append('hourlyAmount', parseFloat(newHourlyAmount));
    formData.append('deviceNumber', newDeviceNumber);
    if (newProductImage) {
      formData.append('productImage', newProductImage);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/client-products/add-product', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setProducts([...products, response.data]);
      setMessage('Product added successfully');
      setNewProduct('');
      setNewDepositAmount('');
      setNewHourlyAmount('');
      setNewDeviceNumber('');
      setNewProductImage(null);
    } catch (error) {
      console.error('Error adding product', error.response ? error.response.data : error.message);
      setMessage('Error adding product');
    }
  };

  const handleActivateDevice = async (deviceNumber) => {
    try {
      const response = await fetch(`/device/set-command/${deviceNumber}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the auth token if needed
        },
        body: JSON.stringify({ command: 'activate' }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error activating device:', errorData.message);
        return;
      }
  
      const result = await response.json();
      console.log('Device activated successfully:', result);
    } catch (error) {
      console.error('Error sending activate command:', error);
    }
  };  

  const handleEditProduct = (product) => {
    setEditProductId(product.id);
    setEditProductName(product.product_name);
    setEditDepositAmount(product.deposit_amount);
    setEditHourlyAmount(product.hourly_amount);
    setEditDeviceNumber(product.device_number);
    setEditProductImage(null); // Clear the previous edit image state
  };

  const handleUpdateProduct = async () => {
    const formData = new FormData();
    formData.append('productId', editProductId);
    formData.append('clientId', user.id);
    formData.append('productName', editProductName);
    formData.append('depositAmount', parseFloat(editDepositAmount));
    formData.append('hourlyAmount', parseFloat(editHourlyAmount));
    formData.append('deviceNumber', editDeviceNumber);
    if (editProductImage) {
      formData.append('productImage', editProductImage);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/client-products/update-product', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      const updatedProducts = products.map(product =>
        product.id === editProductId ? response.data : product
      );
      setProducts(updatedProducts);
      setMessage('Product updated successfully');
      setEditProductId(null);
      setEditProductName('');
      setEditDepositAmount('');
      setEditHourlyAmount('');
      setEditDeviceNumber('');
      setEditProductImage(null);
    } catch (error) {
      console.error('Error updating product', error.response ? error.response.data : error.message);
      setMessage('Error updating product');
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/client-products/delete-product', {
        clientId: user.id,
        productId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setProducts(products.filter(product => product.id !== productId));
      setMessage('Product deleted successfully');
    } catch (error) {
      console.error('Error deleting product', error.response ? error.response.data : error.message);
      setMessage('Error deleting product');
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {message && (
        <div className={`message-popup ${!isVisible ? 'message-popup-hidden' : ''}`}>
          {message}
        </div>
      )}
  
      <div className="device-management-container">
        <h2>Device Management</h2>
  
        <div>
          <a href={`/users/${user.id}.html`} target="_blank" rel="noopener noreferrer" className="html-button">
            User HTML Page
          </a>
        </div>
  
        <div className="device-management-form">
          <input
            type="text"
            className="product-name"
            value={newProduct}
            onChange={(e) => setNewProduct(e.target.value)}
            placeholder="Product Name"
          />
          <input
            type="number"
            className="deposit-amount"
            value={newDepositAmount}
            onChange={(e) => setNewDepositAmount(e.target.value)}
            placeholder="Deposit Amount"
          />
          <input
            type="number"
            className="hourly-amount"
            value={newHourlyAmount}
            onChange={(e) => setNewHourlyAmount(e.target.value)}
            placeholder="Hourly Amount"
          />
          <input
            type="text"
            className="device-number"
            value={newDeviceNumber}
            onChange={(e) => setNewDeviceNumber(e.target.value)}
            placeholder="Device Number"
          />
  
          {/* Styled Button for Uploading Image */}
          <label htmlFor="product-image" className="product-image-button-add">
            Choose Image
          </label>
          <input
            type="file"
            id="product-image"
            className="product-image"
            onChange={(e) => setNewProductImage(e.target.files[0])}
          />
  
          <button onClick={handleAddProduct}>Add Product</button>
        </div>
  
        <div className="product-list">
          <h3>Your Products</h3>
          <ul className="product-list-items">
            {products.map(product => (
              <li key={product.id} className="product-list-item">
                {editProductId === product.id ? (
                  <div className="product-edit-form">
                    <label htmlFor="edit-product-name">Name</label>
                    <input
                      type="text"
                      id="edit-product-name"
                      value={editProductName}
                      onChange={(e) => setEditProductName(e.target.value)}
                      placeholder="Product Name"
                      className="edit-input"
                    />
  
                    <label htmlFor="edit-deposit-amount">Deposit</label>
                    <input
                      type="number"
                      id="edit-deposit-amount"
                      value={editDepositAmount}
                      onChange={(e) => setEditDepositAmount(e.target.value)}
                      placeholder="Deposit Amount"
                      className="edit-input"
                    />
  
                    <label htmlFor="edit-hourly-amount">Hourly Amount</label>
                    <input
                      type="number"
                      id="edit-hourly-amount"
                      value={editHourlyAmount}
                      onChange={(e) => setEditHourlyAmount(e.target.value)}
                      placeholder="Hourly Amount"
                      className="edit-input"
                    />
  
                    <label htmlFor="edit-device-number">Device Number</label>
                    <input
                      type="text"
                      id="edit-device-number"
                      value={editDeviceNumber}
                      onChange={(e) => setEditDeviceNumber(e.target.value)}
                      placeholder="Device Number"
                      className="edit-input"
                    />
  
                    <label htmlFor="edit-product-image" className="product-image-button-edit">
                      Change Image
                    </label>
                    <input
                      type="file"
                      id="edit-product-image"
                      className="edit-product-image"
                      onChange={(e) => setEditProductImage(e.target.files[0])}
                    />
  
                    <div className="product-edit-actions">
                      <button className="update-button" onClick={handleUpdateProduct}>
                        Update
                      </button>
                      <button className="cancel-button" onClick={() => setEditProductId(null)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="product-list-content">
                    <div className="product-actions">
                      <button className="edit-button" onClick={() => handleEditProduct(product)}>Edit</button>
                      <button className="activate-button" onClick={() => handleActivateDevice(product.device_number)}>Unlock</button>
                      <button className="delete-button" onClick={() => handleDeleteProduct(product.id)}>x</button>
                    </div>
                    {product.product_image && (
                      <div className="product-image-wrapper">
                        <img src={product.product_image} alt={product.product_name} className="product-image" />
                      </div>
                    )}
                    <div className="product-info">
                      <div className="product-card-name">{product.product_name}</div>
                      <div className="product-details">
                        <div className="product-deposit">Deposit: ${product.deposit_amount}</div>
                        <div className="product-hourly">Hourly Amount: ${product.hourly_amount}</div>
                        <div className="product-device-number">Device Number: {product.device_number}</div>
                        <div className="product-device-status">
                          Status: {deviceStatuses[product.device_number] || 'Loading...'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DeviceManagement;
