import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from './components/Home';
import AdminDashboard from './components/admincomps/AdminDashboard';
import UserDashboard from './components/clientcomps/ClientDashboard';
import DeviceManagement from './components/clientcomps/DeviceManagement';
import UserSettings from './components/clientcomps/ClientSettings';
import StripeCheckout from './components/StripeCheckout';
import AdminRentalStatus from './components/admincomps/AdminRentalStatus';
import RentalInformation from './components/clientcomps/RentalInformation';
import PaymentSuccess from './components/clientcomps/PaymentSuccess';
import Login from './components/Login';
import StorePage from './components/Store';
import ContactPage from './components/Contact';
import FAQPage from './components/FAQ';
import DeviceSetup from './components/clientcomps/DeviceSetup'; // Import DeviceSetup component
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          {/* Public routes */}
          <Route path="/" exact component={LandingPage} />
          <Route path="/login" component={Login} />
          <Route path="/store" component={StorePage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/FAQ" component={FAQPage} />

          {/* Public checkout and payment success pages */}
          <Route
            path="/checkout/:productId"
            component={(props) => <StripeCheckout productId={props.match.params.productId} />}
          />
          <Route path="/payment-success" component={PaymentSuccess} />

          {/* Previously protected routes are now public */}
          <Route path="/admin" component={AdminDashboard} />
          <Route path="/admin/rental-status" component={AdminRentalStatus} />
          <Route path="/dashboard" component={UserDashboard} />
          <Route path="/device-management" component={DeviceManagement} />
          <Route path="/settings" component={UserSettings} />
          <Route path="/rental-information" component={RentalInformation} />

          {/* Device Setup route */}
          <Route path="/device-setup" component={DeviceSetup} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
