import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './css/Contact.css';
import logo from './img/logo.png';

const ContactPage = () => {
  const [phone, setPhone] = useState(""); // State to handle phone number input
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  // Format phone number as the user types
  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
    const formattedPhone = formatPhoneNumber(value);
    setPhone(formattedPhone); // Update the state with the formatted phone number
  };

  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    if (value.length < 4) {
      return value;
    } else if (value.length < 7) {
      return `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`Form submitted! Phone: ${phone}`);
  };

  return (
    <div className="contact-page">
      <header className="landing-header">
        <img src={logo} alt="Company Logo" className="logo" />
        <div className="header-right">
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/store">Products</Link></li>
              <li><Link to="/FAQ">FAQ</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
          <button onClick={redirectToLogin} className="login-button-horizontal">Login</button>
        </div>
      </header>

        <div className="contact-container">
          <h1>Contact Us</h1>
          <p>Feel free to reach out directly or provide your contact information, and we will contact you directly.</p>
          <h2>Christopher Reiss</h2>
          <p className="contact-info">203-722-9594</p>
          <p className="contact-info">chris.reiss@ktrentals.net</p>
          <p className="contact-info">Founder and Manager</p>

        {/* Contact Form */}
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="contact-form-row">
            <div className="contact-form-group">
              <input type="text" id="first-name" name="first-name" placeholder="First Name" required />
            </div>
            <div className="contact-form-group">
              <input type="text" id="last-name" name="last-name" placeholder="Last Name" required />
            </div>
          </div>

          <div className="contact-form-group">
            <input type="text" id="company" name="company" placeholder="Company" />
          </div>

          <div className="contact-form-group">
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="(000) 000-0000"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="14" // Limit input to the formatted phone number length
            />
          </div>

          <div className="contact-form-group">
            <input type="email" id="email" name="email" placeholder="Email" required />
          </div>

          <button type="submit" className="contact-submit-button">Submit</button>
        </form>
      </div>

      <footer className="landing-footer">
        <p>© 2024 Your Company. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </nav>
      </footer>
    </div>
  );
};

export default ContactPage;
