import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import './css/StripeCheckoutForm.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeCheckoutForm = ({ clientSecret, customerInfo }) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true); // Disable the button during processing

    if (!stripe || !elements) {
      setErrorMessage('Stripe is not loaded yet.');
      setIsProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${customerInfo.firstName} ${customerInfo.lastName}`,
            email: customerInfo.email,
            phone: customerInfo.phoneNumber,
          },
        },
      });

      if (error) {
        console.error('Error confirming card payment:', error);
        setErrorMessage(error.message);
        setIsProcessing(false); // Re-enable the button
      } else {
        console.log('Payment status:', paymentIntent.status);
        if (paymentIntent.status === 'requires_capture') {
          // Payment is authorized, redirect to the success page
          console.log('Payment authorized, redirecting to success page...');
          history.push('/payment-success');
        } else {
          setErrorMessage(`Unexpected payment status: ${paymentIntent.status}`);
        }
        setIsProcessing(false); // Re-enable the button
      }
    } catch (err) {
      console.error('Payment confirmation failed:', err);
      setErrorMessage(err.message);
      setIsProcessing(false); // Re-enable the button
    }
  };

  return (
    <div className="payment-container">
      <form onSubmit={handleSubmit} className="contentBox" autoComplete="off">
        <CardElement className="card-element" />
        <button type="submit" disabled={!stripe || !clientSecret || isProcessing} className="submit-button" data-product-id={clientSecret}>
          {isProcessing ? 'Processing...' : 'Pay'}
        </button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </form>
    </div>
  );
};

const StripeCheckout = ({ productId }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });
  const history = useHistory();

  // Function to format the phone number as (XXX) XXX-XXXX
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevState) => ({
      ...prevState,
      [name]: name === 'phoneNumber' ? formatPhoneNumber(value) : value,
    }));
  };

  const handleCreatePaymentIntent = async (e) => {
    e.preventDefault();
  
    // Basic phone number validation (exactly 10 digits)
    const phonePattern = /^\d{10}$/;
    const rawPhoneNumber = customerInfo.phoneNumber.replace(/[^\d]/g, ''); // Remove formatting for validation
    if (!phonePattern.test(rawPhoneNumber)) {
      alert("Please enter a valid 10-digit phone number.");
      return;
    }
  
    // Basic email validation
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailPattern.test(customerInfo.email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    // Proceed to create the payment intent
    try {
      const token = localStorage.getItem('token');
  
      const paymentIntentResponse = await fetch('/stripe/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          productId,
          ...customerInfo,
        }),
      });
  
      if (!paymentIntentResponse.ok) {
        throw new Error(`HTTP error! status: ${paymentIntentResponse.status}`);
      }
  
      const paymentIntentData = await paymentIntentResponse.json();
      setClientSecret(paymentIntentData.clientSecret);
    } catch (error) {
      console.error('Error fetching payment intent:', error);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="center-container">
        <div className="contentBox">
          <div className="form-header">
            <div className="back-button-container">
              <button onClick={history.goBack} className="back-button">
                &#10005;
              </button>
            </div>
            <h3 className="center-header">{clientSecret ? "Payment Information" : "Rental Information"}</h3>
          </div>
          <div className="form-content">
            {!clientSecret ? (
              <form onSubmit={handleCreatePaymentIntent} autoComplete="off">
                <div>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={customerInfo.firstName}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={customerInfo.lastName}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={customerInfo.phoneNumber}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                    autoComplete="off"
                    pattern="^\(\d{3}\) \d{3}-\d{4}$"  // Ensures formatted phone number
                    title="Phone number must be in the format (XXX) XXX-XXXX."
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={customerInfo.email}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                    autoComplete="off"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"  // Ensures a valid email format
                    title="Please enter a valid email address."
                  />
                </div>
                <button type="submit" className="create-intent-button">
                  Proceed to Payment
                </button>
              </form>
            ) : (
              <StripeCheckoutForm clientSecret={clientSecret} customerInfo={customerInfo} />
            )}
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default StripeCheckout;
