import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/clientcss/RentalInformation.css'; // Import the CSS file

const RentalInformation = () => {
  const [rentalInfo, setRentalInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchRentalInformation = async () => {
      setIsLoading(true);
      try {
        // Get token from localStorage
        const token = localStorage.getItem('token');
  
        // Fetch user info using the token
        const userResponse = await axios.get('/user/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        setUser(userResponse.data); // Set the user data to state
  
        // Fetch rental information filtered by customer_id (user account)
        const rentalsResponse = await axios.get(`/rental/rental_status/${userResponse.data.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Check if the response data is an array
        if (Array.isArray(rentalsResponse.data)) {
          // Sort the data by 'last_updated' in descending order
          const sortedData = rentalsResponse.data.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
  
          setRentalInfo(sortedData); // Set the sorted rental information
        } else {
          setError('Unexpected response format');
        }
  
      } catch (error) {
        console.error('Error fetching rental information:', error);
        setError('Error fetching rental information');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchRentalInformation();
  }, []);  

  if (isLoading) {
    return <p className="loading">Loading...</p>;
  }

  if (error) {
    return <p className="error">{error}</p>;
  }

  const formatAmount = (amountInCents) => {
    return `$${(amountInCents / 100).toFixed(2)}`;
  };

  const formatHourlyAmount = (hourlyAmount) => {
    return `$${parseFloat(hourlyAmount).toFixed(2)}`;
  };

  const formatDateTime = (dateTime) => {
    return dateTime ? new Date(dateTime).toLocaleString() : 'N/A';
  };

  return (
    <div className="rental-information-container">
      <h1>Rental Information</h1>
      <table>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Status</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Product ID</th>
            <th>Hold Amount</th>
            <th>Hourly Rental Amount</th>
            <th>Amount Capturable</th>
            <th>Amount Received</th>
            <th>Rental Start</th>
            <th>Rental End</th>
            <th>Total Rental Time</th>
            <th>Total Transaction Amount</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {rentalInfo.map((rental) => (  // No reverse needed
            <tr key={rental.id}>
              <td>{rental.id}</td>
              <td>{rental.status}</td>
              <td>{rental.metadata ? rental.metadata.first_name : 'N/A'}</td>
              <td>{rental.metadata ? rental.metadata.last_name : 'N/A'}</td>
              <td>{rental.metadata ? rental.metadata.email : 'N/A'}</td>
              <td>{rental.metadata ? rental.metadata.phone_number : 'N/A'}</td>
              <td>{rental.product_id}</td>
              <td>{formatAmount(rental.amount)}</td>
              <td>{rental.metadata ? formatHourlyAmount(rental.metadata.hourly_rental_amount) : 'N/A'}</td>
              <td>{formatAmount(rental.amount_capturable)}</td>
              <td>{formatAmount(rental.amount_received)}</td>
              <td>{formatDateTime(rental.rental_start_time)}</td>
              <td>{formatDateTime(rental.device_returned_time)}</td>
              <td>{rental.total_rental_time ? `${rental.total_rental_time.hours || 0}h ${rental.total_rental_time.minutes || 0}m ${rental.total_rental_time.seconds || 0}s` : 'N/A'}</td>
              <td>{rental.total_transaction_amount && rental.total_transaction_amount > 0 ? formatAmount(rental.total_transaction_amount) : 'N/A'}</td>
              <td>{new Date(rental.last_updated).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RentalInformation;
